/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import React from 'react';
import { ContentStackRichTextToc } from '../../interface';
import { Text, View } from '@az/starc-ui';
import styles from './CMSRichTextTOC.module.scss';

type Props = { content: ContentStackRichTextToc };

export function CMSRichTextTOC({ content }: Props) {
  return (
    <View direction="column" gap={2}>
      <View.Item>
        <Text variant="display-1">{content.headline}</Text>
      </View.Item>
      {content.top_description && (
        // @ts-expect-error children is the HTML content
        <Text
          attributes={{
            dangerouslySetInnerHTML: {
              __html: content.top_description,
            },
          }}
        />
      )}
      <View.Item
        className={styles.tocContainer}
        attributes={{
          dangerouslySetInnerHTML: { __html: content.content },
        }}
      ></View.Item>
    </View>
  );
}
