/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import type { Locale } from '@/types/i18n';

export const landingPagesHandledByContentStack: Record<Exclude<Locale, 'pt-BR'>, string[]> = {
  'en-US': [
    'affiliate-program',
    'alldatadiy',
    'allstate-offer',
    'autozone-savings',
    'battery-services',
    'car-battery-guide',
    'cms-playground',
    'custom-accessories',
    'deals',
    'drive-for-autozone',
    'duralast-alternators',
    'duralast-amortiguadores',
    'duralast-battery',
    'duralast-brakes',
    'duralast-elite-brake-pads',
    'duralast-starters',
    'duralast-tools',
    'duralast',
    'extreme-cold-driving',
    'faster',
    'free-parts-testing',
    'maximize-fuel-efficiency',
    'mobile-app',
    'next-day-delivery',
    'oil-change-specials',
    'online-shopping-fast-delivery',
    'rewards',
    'rv-and-marine-battery',
    'same-day-delivery',
    'severe-weather',
    'store-pick-up',
    'store-services',
    'summer-car-must-haves',
    'warranty',
    'recommendations',
  ],
  'es-MX': [
    'afinacion-automotriz',
    'beneficios',
    'comunicado',
    'deals',
    'desarrollo-de-proveedores',
    'expansion-de-tiendas',
    'informacion-corporativa',
    'luces-de-tablero',
    'oil-change-specials',
    'autozone-savings',
    'bolsa-de-trabajo',
    'check-engine',
    'duralast',
    'duralast-amortiguadores',
    'duralast-brakes',
    'duralast-battery',
    'duralast-baterias',
    'duralast-starters',
    'duralast-alternators',
    'duralast-tools',
    'duralast-herramientas',
    'herramienta-de-prestamo',
    'cms-playground',
    'car-battery-guide',
    'buen-fin',
    'rewards',
    'drive-for-autozone',
    'servicios-en-tiendas',
    'fix-finder',
    'clic-compra-y-recoge',
    'factura-electronica',
    'folleto',
    'valvoline-aceite',
  ],
};

export const temporaryCSLandingPages = ['buen-fin'];
