/**
 * Copyright 2023 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type { FormattedContentStackLandingPage } from '@/features/contentstack/interface';
import { CMSRichTextTOC } from '@/features/contentstack/components/CMSRichTextTOC/CMSRichTextTOC';
import { CMSSpacer } from '@/features/contentstack/components/CMSSpacer/CMSSpacer';

type Props = {
  content: FormattedContentStackLandingPage['bottomModularBlocks'];
};

export const CMSBottomModularHandler = ({ content }: Props) => {
  return (
    <>
      {content?.map((modularBlock, i) => {
        if (modularBlock.rich_text_toc.rich_text_toc?.[0])
          return (
            <CMSSpacer key={i}>
              <CMSRichTextTOC content={modularBlock.rich_text_toc.rich_text_toc[0]} />
            </CMSSpacer>
          );
      })}
    </>
  );
};
