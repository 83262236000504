/**
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { getAxiosForClient } from '@/lib/axios';
import {
  QueryClient,
  QueryFunctionContext,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query';
import type { LandingPageData } from '@/types/legacy';
import { PageContextType } from '../../../next-types/index';
import type { FormattedContentStackLandingPage } from '@/features/contentstack/interface';
import { getContentStackLandingPageContent } from '@/features/contentstack/lib/landingPage/getContentStackLandingPageContent'; // eslint-disable-line no-restricted-imports
import { useSegments } from '@/features/header/api/getSegments';
import { useLocale } from '@/hooks/useLocale';
import { countryCodes } from '@/constants/locale';

type Options = {
  pageName: string | undefined;
};

const URL = `/api/contentstack/landingPages`;

const getContentStackLandingPageData = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof getLandingPageDataKey>>) => {
  const [, , { pageName, segments }] = queryKey;

  const params = {
    ...(segments.length && {
      segments: segments.join(','),
    }),
  };

  const response = await getAxiosForClient().get<FormattedContentStackLandingPage>(
    `${URL}/${pageName ?? ''}`,
    { params }
  );
  return selector(response.data);
};

const selector = (data: FormattedContentStackLandingPage): FormattedContentStackLandingPage => data;

const getLandingPageDataKey = (pageName: string | undefined, segments: string[]) =>
  [
    'cms',
    'landingPage',
    {
      pageName,
      segments,
    },
  ] as const;

export const useGetContentStackLandingPageData = (
  options: Omit<
    UseQueryOptions<
      FormattedContentStackLandingPage,
      unknown,
      FormattedContentStackLandingPage,
      ReturnType<typeof getLandingPageDataKey>
    > &
      Options,
    'queryKey' | 'queryFn'
  >
) => {
  const { data: segments, isSuccess } = useSegments();
  const locale = useLocale();
  return useQuery({
    queryKey: getLandingPageDataKey(options.pageName, segments ?? []),
    queryFn: getContentStackLandingPageData,
    enabled: (options.enabled ?? true) && (isSuccess || locale === countryCodes.ptBr),
  });
};

export const getContentStackLandingPageDataFromCache = (
  queryClient: QueryClient,
  pageName: string,
  segments: string[]
): LandingPageData | undefined => {
  return queryClient.getQueryData(getLandingPageDataKey(pageName, segments));
};

export const prefetchContentStackLandingPageData = (
  queryClient: QueryClient,
  context: PageContextType,
  segments: string[]
) => {
  return queryClient.prefetchQuery({
    queryKey: getLandingPageDataKey(context.query.pageName, segments),
    queryFn: () => getContentStackLandingPageContent(context.req, context.query, segments),
  });
};
